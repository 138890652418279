import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import CommonServices from "@/services/common.services";

const getHotelDetails = ({state, commit, dispatch}, payload) => {

  return CommonServices.getHotelDetails(payload.property_id)
    .then(res => {
      let hotel_data = res.data;
      if (hotel_data.website_config.google_analytics_ids) {
        if(hotel_data.website_config.google_analytics_ids.startsWith("GTM")){
          Vue.use(VueGtm, {
            id: hotel_data.website_config.google_analytics_ids,
            enabled: true,
            debug: process.env.NODE_ENV === 'development'
          })
        } else {
          var pl = document.createElement('script');
          pl.type = 'text/javascript';
          pl.async = true;
          pl.src = 'https://www.googletagmanager.com/gtag/js?id=' + hotel_data.website_config.google_analytics_ids;
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          window.dataLayer = window.dataLayer || [];
          window.gtag = function() { window.dataLayer.push(arguments);};
          gtag('js', new Date());
          gtag('config', hotel_data.website_config.google_analytics_ids);
        }
      }

      commit('SET_HOTEL_DETAILS', hotel_data);
      commit('SET_PROPERTY_CURRENCY', hotel_data.currency);

      // let reswcover = {...res.data};
      // reswcover.cover_photo = {url: reswcover.cover_photo};
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = res.data.business_logo;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.getElementsByTagName('title')[0].innerHTML = 'Booking | ' + res.data.name;

      if (!(!!state.amenities && state.amenities.length > 0) && !(!!state.room_types && state.room_types.length > 0) && !(!!state.charge_types && state.charge_types.length > 0)) {
        commit('configs/SET_MINIMUM_DATE', res.data.business_day.date.split('T')[0], {root: true});
        // dispatch('setWizardSteps')
      }
      if (!(!!state.amenities && state.amenities.length > 0) && !(!!state.room_types && state.room_types.length > 0) && !(!!state.charge_types && state.charge_types.length > 0)) {
        commit('SET_AMENITIES', res.data.room_types);
      }
      return hotel_data
    })
};

const setWizardSteps = async ({commit, rootState, rootGetters}, stepsToRemove) => {

  let raw_create_wizard_steps = rootGetters['allSteps'];
  if(stepsToRemove?.length > 0)
    await stepsToRemove.forEach(removeStep => {
      if (raw_create_wizard_steps[removeStep]) delete raw_create_wizard_steps[removeStep]
    })
  let create_wizard_steps = {...raw_create_wizard_steps};
  let index = 0;
  for (let key in create_wizard_steps) {
    create_wizard_steps[key].index = index;
    index += 1;
  }

  commit('SET_STEPS', create_wizard_steps, {root: true});
  if(rootState.current_step){} else commit('SET_CURRENT_STEP', 'search', {root: true})
  //commit('CHANGE_STEP', 'search', {root: true});
};

export default {
  getHotelDetails,
  setWizardSteps,
};
